import React from "react"
import styled from "styled-components"
import SEO from "../components/SEO"

export default function ContactPage() {
  const FormStyle = styled.div`
    display: flex;
    justify-content: center;
    --background: #e6e6e6;
    .form {
      object-fit: contain;
      overflow: hidden;
      width: 500px;
      height: 600px;
      background: var(--background);
      border-radius: 8px;
      box-shadow: 0 0 40px -10px #000;
      margin: 3rem 0 3rem 0;
      padding: 20px 30px;
      max-width: calc(100vw - 40px);
      box-sizing: border-box;
      position: relative;
    }
    h1 {
      font-size: var(--xtra-big-ass-heading);
      margin: 10px 0;
      padding-bottom: 10px;
      width: 180px;
      color: var(--blue);
      border-bottom: 3px solid var(--green);
    }
    input {
      width: 100%;
      padding: 1rem;
      margin-bottom: 1rem;
      box-sizing: border-box;
      background: none;
      outline: none;
      resize: none;
      border: 0;
      transition: all 0.3s;
      border-bottom: 2px solid #bebed2;
    }
    input:focus {
      border-bottom: 2px solid #78788c;
    }
    p:before {
      content: attr(type);
      display: block;
      margin: 28px 0 0;
      font-size: 2rem;
      color: var(--blue);
    }
    button {
      background: var(--blue);
    }
    button:hover {
      background: var(--blue);
      color: white;
    }
    .clear {
      width: auto;
      display: inline-block;
      box-sizing: none;
      background: var(--red);
      color: white;
      border: 0;
      padding: 0.6rem 1rem;
      border-radius: 2px;
      cursor: pointer;
      --cast: 2px;
      margin-bottom: 0;
      box-shadow: var(--cast) var(--cast) 0 var(--grey);
      text-shadow: 0.5px 0.5px 0 rgba(0, 0, 0, 0.2);
      transition: all 0.2s;
      &:hover {
        background: var(--blue);
        --cast: 4px;
      }
    }
    textarea {
      margin-top: 1rem;
      background: var(---background);
      width: 100%;
    }
    div {
      padding-top: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    @media (max-width: 600px) {
      .form {
        width: 70vw;
        height: auto;
      }
      label {
        font-size: var(--heading-2);
      }
    }
  `
  return (
    <FormStyle>
      <SEO title="contact page" />
      <main role="main">
        <div role="form">
          <form
            className="form"
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <h1>Contact Me</h1>
            <label>
              Name
              <input
                type="text"
                name="name"
                id="name"
                placeholder="What should I call you?"
              />
            </label>
            <label>
              Email
              <input
                type="email"
                name="email"
                id="email"
                placeholder="How do I contact you?"
              />
            </label>
            <label>
              Subject
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder="What's up?"
              />
            </label>
            <label>
              Message
              <textarea
                name="message"
                id="message"
                rows="5"
                placeholder="Tell me a story."
              />
            </label>
            <div>
              <input className="clear" type="reset" value="Clear" />
              <button type="submit">Send</button>
            </div>
          </form>
        </div>
      </main>
    </FormStyle>
  )
}
